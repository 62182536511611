import { Col, Drawer, Row } from 'antd';
import { egeniePcTheme, formatNumber } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { ENUM_MODULE_TYPE } from '../../../moduleEfficiency/constants';
import type { AreaDisplayWrapperProps } from '../areaDisplayWrapper/areaDisplayWrapper';
import { AreaDisplayWrapper } from '../areaDisplayWrapper/areaDisplayWrapper';
import { AreaSettingModal } from '../areaSettingModal/areaSettingModal';
import type { CostPreviewProps } from '../costPreview/costPreview';
import { CostPreview } from '../costPreview/costPreview';
import type { PreviewModalStore } from './previewModalStore';

const gutter = parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']);

@observer
class Content extends React.Component<{ previewModalStore: PreviewModalStore; }> {
  render() {
    const {
      handleAdd,
      handleEdit,
      moduleConfigDetail,
    } = this.props.previewModalStore;

    const arrivalData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.arrival.label,
      style: { height: 245 },
      data: moduleConfigDetail?.arrivalAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.arrival.value,
      handleAdd,
      handleEdit,
    };

    const deliverData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.deliver.label,
      style: { height: 439 },
      data: moduleConfigDetail.deliveryAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.deliver.value,
      handleAdd,
      handleEdit,
    };

    const expressCollectionData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.expressCollection.label,
      style: { height: 160 },
      data: moduleConfigDetail?.collectAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.expressCollection.value,
      handleAdd,
      handleEdit,
      tooltipRender(item) {
        return (
          <>
            <p>
              设备名称:&nbsp;
              {item.name}
            </p>
            <p>
              快递公司:&nbsp;
              {item.courierName}
            </p>
          </>
        );
      },
    };

    const secondarySortingData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.secondarySorting.label,
      style: { height: 176 },
      data: moduleConfigDetail?.sortAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.secondarySorting.value,
      handleAdd,
      handleEdit,
    };

    const storageData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.storage.label,
      style: { height: 676 },
      data: moduleConfigDetail.storageAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.storage.value,
      handleAdd,
      handleEdit,
    };

    const plusServiceData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.plusService.label,
      style: { height: 452 },
      data: moduleConfigDetail?.valueAddedAreaDetails || [],
      moduleType: ENUM_MODULE_TYPE.plusService.value,
      handleAdd,
      handleEdit,
    };

    const refundVendorData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.refundVendor.label,
      style: { height: 222 },
      data: moduleConfigDetail?.refundAreaDetails,
      moduleType: ENUM_MODULE_TYPE.refundVendor.value,
      handleAdd,
      handleEdit,
    };

    const afterSaleData: AreaDisplayWrapperProps = {
      title: ENUM_MODULE_TYPE.afterSale.label,
      style: { height: 222 },
      data: moduleConfigDetail?.afterSaleAreaDetails,
      moduleType: ENUM_MODULE_TYPE.afterSale.value,
      handleAdd,
      handleEdit,
    };

    const moduleCostPreviewData: CostPreviewProps = {
      data: {
        deviceCost: formatNumber(moduleConfigDetail?.deviceCost),
        laborCost: formatNumber(moduleConfigDetail?.laborCost),
        saturationAging: '-',
        saturationNum: '-',

        // saturationAging: formatNumber(moduleConfigDetail?.saturationAging),
        // saturationNum: formatNumber(moduleConfigDetail?.saturationNum),
      },
    };

    return (
      <div
        style={{
          overflowX: 'hidden',
          padding: gutter,
        }}
      >
        <Row
          gutter={[
            gutter,
            gutter,
          ]}
        >
          <Col span={6}>
            <Row
              gutter={[
                gutter,
                gutter,
              ]}
            >
              <Col span={24}>
                <AreaDisplayWrapper {...arrivalData}/>
              </Col>
              <Col span={24}>
                <AreaDisplayWrapper {...deliverData}/>
              </Col>
              <Col span={24}>
                <AreaDisplayWrapper {...expressCollectionData}/>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row
              gutter={[
                gutter,
                gutter,
              ]}
            >
              <Col span={24}>
                <AreaDisplayWrapper {...secondarySortingData}/>
              </Col>
              <Col span={24}>
                <AreaDisplayWrapper {...storageData}/>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row
              gutter={[
                gutter,
                gutter,
              ]}
            >
              <Col span={24}>
                <Row
                  gutter={[
                    gutter,
                    gutter,
                  ]}
                >
                  <Col span={12}>
                    <AreaDisplayWrapper {...plusServiceData}/>
                  </Col>
                  <Col span={12}>
                    <Row
                      gutter={[
                        gutter,
                        gutter,
                      ]}
                    >
                      <Col span={24}>
                        <AreaDisplayWrapper {...refundVendorData}/>
                      </Col>
                      <Col span={24}>
                        <AreaDisplayWrapper {...afterSaleData}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CostPreview {...moduleCostPreviewData}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export const PreviewModal: React.FC<{ previewModalStore: PreviewModalStore; }> = observer(({ previewModalStore }) => {
  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        footer={null}
        mask={false}
        maskClosable={false}
        onClose={previewModalStore.handleClose}
        title="配置模块"
        visible={previewModalStore.visible}
        width="100%"
      >
        <Content previewModalStore={previewModalStore}/>
      </Drawer>
      <AreaSettingModal areaSettingModalStore={previewModalStore.areaSettingModalStore}/>
    </>
  );
});
