export const enumWarehouseType = {
  self: {
    value: '1',
    label: '自建仓',
  },
  third: {
    value: '2',
    label: '三方仓',
  },
};
