import { Space } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructureModel, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { enumWarehouseType } from './constants';
import type { Store } from './store';
import type { TenantItem } from './types';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'id',
          frozen: true,
          name: '操作',
          width: 320,
          formatter: ({ row }) => {
            return (
              <Space>
                <a onClick={() => context.handleSetMainReturnGoodsWarehouse(row)}>
                  设置退货主仓
                </a>
                {
                  row.auditStatus !== 0 ? (
                    <a onClick={() => context.previewModalStore.handleOpen(row)}>
                      配置模块
                    </a>
                  ) : null
                }
                {
                  row.auditStatus === 0 ? (
                    <a onClick={() => context.handleEditUser(row)}>
                      编辑
                    </a>
                  ) : null
                }
                {
                  row.auditStatus === 0 ? (
                    <>
                      <a onClick={() => context.handleDelete(row)}>
                        删除
                      </a>
                      <a onClick={() => context.handleApprove(row)}>
                        审核
                      </a>
                    </>
                  ) : (
                    <span>
                      已审核
                    </span>
                  )
                }
                <a onClick={() => context.handleModifyRefundAddress(row)}>
                  设置退货地址
                </a>
              </Space>
            );
          },
        },
        {
          width: 250,
          key: 'warehouseName',
          name: '仓库名称',
        },
        {
          width: 250,
          key: 'refundAddress',
          name: '退货地址',
        },
        {
          key: 'tenantModuleSet',
          name: '产品授权',
          width: 220,
          formatter: ({ row }) => {
            const { tenantModuleSet } = row;
            return (
              <div>
                {tenantModuleSet?.map((el) => {
                  return (
                    <span
                      key={el.moduleCode}
                      style={{ marginRight: '8px' }}
                    >
                      {`${el.moduleName || ''}`}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          key: 'phone',
          name: '手机号',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 200,
          formatter: ({ row }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'tenantType',
          name: '云仓类型',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {Object.values(enumWarehouseType).find((item) => item.value == row.tenantType)?.label}
              </span>
            );
          },
        },
        {
          key: 'refundWarehouse',
          name: '退货主仓',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {row.refundWarehouse ? '是' : '否'}
              </span>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
    },

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<TenantItem>>({
          url: '/api/cloud/wms/rest/tenant/registry/query',
          method: 'POST',
          data: {
            ...rest,
            ...filterParams,
          },
        });
      },
    },
    hiddenSubTable: true,
    pageId: '60054',
    buttons: [
      {
        permissionId: '4030',
        text: '创建云仓租户',
        handleClick: context.handleAddUser,
      },
    ],
  });
}
