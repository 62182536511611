export const ENUM_STORAGE_PLAN_USE = {
  storage: {
    value: 1,
    label: '存储 ',
  },
  task: {
    value: 2,
    label: '作业 ',
  },
};

export const ENUM_PLUS_SERVICE_PLAN_USE = {
  exchangeDecorate: {
    value: 3,
    label: '换包装',
  },
  qualityTesting: {
    value: 4,
    label: '质检',
  },
  exchangeCollarLabel: {
    value: 5,
    label: '换标',
  },
};

export const ENUM_MODULE_TYPE = {
  arrival: {
    value: 1,
    label: '到货区',
  },
  deliver: {
    value: 2,
    label: '发货区',
  },
  secondarySorting: {
    value: 3,
    label: '二次分拣区',
  },
  expressCollection: {
    value: 4,
    label: '快递揽收区',
  },
  storage: {
    value: 5,
    label: '存储区',
    showPlanUse: true,
    planUseData: Object.values(ENUM_STORAGE_PLAN_USE),
    planUseDefault: ENUM_STORAGE_PLAN_USE.storage.value,
  },
  plusService: {
    value: 6,
    label: '增值服务区',
    showPlanUse: true,
    planUseData: Object.values(ENUM_PLUS_SERVICE_PLAN_USE),
    planUseDefault: ENUM_PLUS_SERVICE_PLAN_USE.exchangeDecorate.value,
  },
  refundVendor: {
    value: 7,
    label: '退货供应商区',
  },
  afterSale: {
    value: 8,
    label: '售后区',
  },
};

export const ENUM_PROGRAMME_PLAN_TYPE = {
  automatic: {
    value: 1,
    label: '自动化方案',
    backgroundColor: '#E6F1FF',
    color: '#1978FF',
  },
  manual: {
    value: 2,
    label: '人工方案',
    backgroundColor: '#E0F9F3',
    color: '#02C190',
  },
};
