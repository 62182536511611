import { Button, Typography, Tooltip } from 'antd';
import classnames from 'classnames';
import { RenderByCondition } from 'egenie-common';
import { nanoid } from 'nanoid';
import React from 'react';
import type { DevicePreviewItem } from '../../types';
import styles from './areaDisplayWrapper.less';

export interface AreaDisplayWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  data: DevicePreviewItem[];
  moduleType: number;
  handleAdd: (...arg: any) => any;
  handleEdit: (...arg: any) => any;
  tooltipRender?: (item: DevicePreviewItem) => React.ReactNode;
}

export const AreaDisplayWrapper: React.FC<AreaDisplayWrapperProps> = function({
  className = '',
  style = {},
  title,
  data = [],
  handleAdd,
  handleEdit,
  moduleType,
  tooltipRender,
}) {
  return (
    <section
      className={classnames(styles.moduleConfigArea, className)}
      style={style}
    >
      <header>
        <h3>
          {title}
        </h3>
        <RenderByCondition show={data.length > 0}>
          <Button
            onClick={() => handleEdit(moduleType)}
            type="link"
          >
            设置
          </Button>
        </RenderByCondition>
      </header>
      <div className={styles.content}>
        <RenderByCondition show={data.length > 0}>
          <>
            {
              data.map((item) => (
                <Tooltip
                  arrowPointAtCenter
                  key={nanoid()}
                  placement="bottom"
                  title={(
                    typeof tooltipRender === 'function'
                      ? tooltipRender(item)
                      : (
                        <>
                          <p>
                            设备名称:&nbsp;
                            {item.name}
                          </p>
                          <p>
                            MAC地址:&nbsp;
                            {item.mac}
                          </p>
                        </>
                      )
                  )}
                >
                  <Typography.Text
                    ellipsis
                    key={nanoid()}
                  >
                    {item.name}
                  </Typography.Text>
                </Tooltip>
              ))
            }
          </>
        </RenderByCondition>
        <RenderByCondition show={data.length === 0}>
          <Tooltip
            arrowPointAtCenter
            placement="bottom"
            title="添加方案"
          >
            <Button
              className={styles.addContainer}
              icon={<i className="icon-add"/>}
              onClick={() => handleAdd(moduleType)}
              type="link"
            />
          </Tooltip>
        </RenderByCondition>
      </div>
    </section>
  );
};
