import { Col, Row, Typography } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './costPreview.less';

export interface CostPreviewProps {
  className?: string;
  style?: React.CSSProperties;
  data: {
    laborCost: number;
    saturationAging: string;
    saturationNum: string;
    deviceCost: number;
  };
}

export const CostPreview: React.FC<CostPreviewProps> = function({
  className = '',
  style = {},
  data,
}) {
  return (
    <section
      className={classnames(styles.moduleCostPreview, className)}
      style={style}
    >
      <h2>
        方案效率成本预览
      </h2>
      <section className={styles.content}>
        <Row>
          <Col span={6}>
            <h2>
              饱和时效
            </h2>
            <Typography.Title
              ellipsis
              level={1}
              title={data.saturationAging}
            >
              {data.saturationAging}
              <span>
                件/小时
              </span>
            </Typography.Title>
          </Col>
          <Col span={6}>
            <h2>
              饱和件数
            </h2>
            <Typography.Title
              ellipsis
              level={1}
              title={data.saturationNum}
            >
              {data.saturationNum}
              件
            </Typography.Title>
            <div className={styles.extraDescription}>
              *按10小时算
            </div>
          </Col>
          <Col span={6}>
            <h2>
              设备成本
            </h2>
            <Typography.Title
              ellipsis
              level={1}
              title={`${data.deviceCost}`}
            >
              {data.deviceCost}
              元
            </Typography.Title>
          </Col>
          <Col span={6}>
            <h2>
              人工成本
            </h2>
            <Typography.Title
              ellipsis
              level={1}
              title={`${data.laborCost}`}
            >
              {data.laborCost}
              <span>
                元/天
              </span>
            </Typography.Title>
          </Col>
        </Row>
      </section>
    </section>
  );
};
