import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { TenantItem, ModuleConfigDetail } from '../../types';
import { AreaSettingModalStore } from '../areaSettingModal/areaSettingModalStore';

export class PreviewModalStore {
  public tenantItem: TenantItem = {};

  public areaSettingModalStore = new AreaSettingModalStore(this);

  @observable public visible = false;

  @observable public moduleConfigDetail: ModuleConfigDetail = {};

  @action public getModuleConfigDetail = (): void => {
    request<BaseData<ModuleConfigDetail>>({ url: `/api/cloud/wms/rest/cloud/report/module/getModuleConfig/${this.tenantItem.tenantId}` })
      .then((info) => this.moduleConfigDetail = info.data || {});
  };

  @action public handleOpen = (tenantItem: TenantItem): void => {
    this.tenantItem = tenantItem;
    this.visible = true;
    this.getModuleConfigDetail();
  };

  @action public handleClose = (): void => {
    this.tenantItem = {};
    this.visible = false;
    this.moduleConfigDetail = {};
  };

  @action public handleAdd = (moduleType: number): void => {
    this.areaSettingModalStore.handleOpen(moduleType, true);
  };

  @action public handleEdit = (moduleType: number): void => {
    this.areaSettingModalStore.handleOpen(moduleType, false);
  };
}
