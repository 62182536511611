import { NormalProgramme } from 'egenie-utils';
import type { Store } from './store';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'warehouseName',
        label: '仓库名称',
      },
      {
        type: 'input',
        field: 'phone',
        label: '手机号',
      },
      {
        type: 'dateRange',
        field: 'createTimeRange',
        label: '创建时间',
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
