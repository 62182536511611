import { Form, Input, Modal } from 'antd';
import React from 'react';
import type { TenantItem } from './types';

interface RefundAddressModalProps {
  callback: (params: { refundAddress: string; }) => Promise<any>;
  onCancel: () => void;
  mainItem?: TenantItem;
}

export function RefundAddressModal({
  callback,
  onCancel,
  mainItem,
}: RefundAddressModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  const initialValues = { refundAddress: mainItem?.refundAddress };

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="设置退货地址"
      visible
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="退货地址"
          name="refundAddress"
          rules={[
            {
              required: true,
              message: '请输入退货地址',
            },
          ]}
        >
          <Input.TextArea
            allowClear
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
            maxLength={200}
            placeholder="请输入退货地址"
            showCount
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
