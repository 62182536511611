import { Button, Drawer, Col, Row, Tag, Typography, Space, InputNumber, Input, Select } from 'antd';
import { egeniePcTheme, RenderByCondition } from 'egenie-common';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { ENUM_PROGRAMME_PLAN_TYPE } from '../../../moduleEfficiency/constants';
import styles from './areaSettingModal.less';
import type { AreaSettingModalStore } from './areaSettingModalStore';
import type { CostPreviewProps } from '../costPreview/costPreview';
import { CostPreview } from '../costPreview/costPreview';

@observer
class ProgrammeList extends React.Component<{ areaSettingModalStore: AreaSettingModalStore; }> {
  render() {
    const {
      areaSettingDetailData,
      handleItemCountChange,
      courierData,
      isExpressCollection,
      getItemCount,
    } = this.props.areaSettingModalStore;
    return (
      <Row
        gutter={[
          parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']),
          parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']),
        ]}
      >
        {
          areaSettingDetailData.map((item, index) => {
            const planTypeItem = Object.values(ENUM_PROGRAMME_PLAN_TYPE)
              .find((val) => _.toString(val.value) === _.toString(item.planType));
            return (
              <Col
                key={item.wmsModulePlanId}
                span={8}
              >
                <div className={styles.programmeItem}>
                  <span className={styles.order}>
                    {index <= 8 ? `0${index + 1}` : index + 1}
                  </span>
                  <Typography.Title
                    ellipsis
                    level={3}
                    title={item.planName}
                  >
                    {item.planName}
                  </Typography.Title>
                  <section style={{ marginTop: parseFloat(egeniePcTheme.spacing['egenie-spacing-xss']) }}>
                    <Tag color={egeniePcTheme.color['egenie-primary-color']}>
                      {item.enable ? '已启用' : '未启用'}
                    </Tag>
                    <RenderByCondition show={Boolean(planTypeItem)}>
                      <Tag
                        color={planTypeItem?.color}
                        style={{
                          backgroundColor: planTypeItem?.backgroundColor,
                          color: planTypeItem?.color,
                        }}
                      >
                        {planTypeItem?.label}
                      </Tag>
                    </RenderByCondition>
                  </section>
                  <section
                    className={styles.description}
                    style={{ marginTop: parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']) }}
                  >
                    <Typography.Text
                      ellipsis
                      title={item.deviceName}
                    >
                      设备:&nbsp;
                      {item.deviceName}
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      饱和容量:&nbsp;
                      {item.saturatedStockNum || 0}
                      件
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      饱和时效:&nbsp;
                      {item.saturationAging || 0}
                      件/小时
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      操作人数:&nbsp;
                      {item.operatorNum || 0}
                      人
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      设备成本:&nbsp;
                      {item.deviceTotalLaborCost || 0}
                      元
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      人工成本:&nbsp;
                      {item.laborCost || 0}
                      元/天
                    </Typography.Text>
                    <InputNumber
                      max={9999}
                      min={0}
                      onChange={(val) => handleItemCountChange(val, index)}
                      placeholder="请输入数量"
                      value={getItemCount(item)}
                    />
                  </section>
                  <RenderByCondition show={isExpressCollection && item.courierInfos.length > 0}>
                    <section
                      className={styles.deviceContainer}

                      // courierInfos的长度改变直接重新创建
                      key={item.courierInfos.length}
                    >
                      <header>
                        快递公司:
                      </header>
                      {
                        item.courierInfos.map((courierItem, courierIndex) => (
                          <div
                            className={styles.deviceItem}
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`${courierIndex}`}
                          >
                            <Select
                              allowClear
                              onChange={(courierId) => courierItem.courierId = courierId}
                              options={courierData}
                              placeholder="请选择快递公司"
                              value={_.toString(courierItem.courierId) || undefined}
                            />
                            <Button
                              onClick={() => item.courierInfos.splice(courierIndex, 1)}
                              type="text"
                            >
                              删除
                            </Button>
                          </div>
                        ))
                      }
                    </section>
                  </RenderByCondition>
                  <RenderByCondition show={!isExpressCollection && item.deviceInfoList.length > 0}>
                    <section
                      className={styles.deviceContainer}

                      // deviceInfoList的长度改变直接重新创建
                      key={item.deviceInfoList.length}
                    >
                      <header>
                        MAC地址:
                      </header>
                      {
                        item.deviceInfoList.map((deviceInItem, deviceIndex) => (
                          <div
                            className={styles.deviceItem}

                            // key不能用随机的,否则input存在焦点问题
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`${deviceIndex}`}
                          >
                            <Input
                              allowClear
                              onChange={(event) => deviceInItem.mac = event.target.value}
                              placeholder="请输入MAC地址"
                              value={deviceInItem.mac}
                            />
                            <Button
                              onClick={() => item.deviceInfoList.splice(deviceIndex, 1)}
                              type="text"
                            >
                              删除
                            </Button>
                          </div>
                        ))
                      }
                    </section>
                  </RenderByCondition>
                </div>
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

@observer
class Footer extends React.Component<{ areaSettingModalStore: AreaSettingModalStore; }> {
  render() {
    const {
      handleClose,
      getAreaSettingDetail,
      isLoading,
      handleFinish,
      sumDeviceTotalLaborCost,
      sumLaborCost,
      sumSaturationNum,
      sumSaturationAging,
    } = this.props.areaSettingModalStore;

    const costData: CostPreviewProps = {
      data: {
        deviceCost: sumDeviceTotalLaborCost,
        laborCost: sumLaborCost,
        saturationNum: `${sumSaturationNum}`,
        saturationAging: `${sumSaturationAging}`,
      },
      style: { marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] },
    };

    return (
      <>
        <CostPreview {...costData}/>
        <Row justify="end">
          <Space>
            <Button onClick={handleClose}>
              取消
            </Button>
            <Button onClick={getAreaSettingDetail}>
              重置
            </Button>
            <Button
              loading={isLoading}
              onClick={handleFinish}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      </>
    );
  }
}

export const AreaSettingModal: React.FC<{ areaSettingModalStore: AreaSettingModalStore; }> = observer(({ areaSettingModalStore }) => {
  const {
    handleClose,
    visible,
  } = areaSettingModalStore;
  return (
    <Drawer
      footer={<Footer areaSettingModalStore={areaSettingModalStore}/>}
      maskClosable={false}
      onClose={handleClose}
      title="方案设置"
      visible={visible}
      width={980}
    >
      <ProgrammeList areaSettingModalStore={areaSettingModalStore}/>
    </Drawer>
  );
});
