import { Form, Input, Modal, Radio, Select } from 'antd';
import { passwordReg, phoneReg } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import _ from 'lodash';
import React from 'react';
import { enumWarehouseType } from './constants';
import type { TenantItem } from './types';
import { getAddress } from '../../utils';

interface DistributionModalProps {
  callback: (params: TenantItem) => Promise<any>;
  onCancel: () => void;
  mainItem?: TenantItem;
  title: string;
}

export function UserModal({
  callback,
  onCancel,
  title,
  mainItem,
}: DistributionModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  const initialValues = {
    ...mainItem,
    provinceType: _.toString(mainItem?.provinceType) || undefined,
    cityType: _.toString(mainItem?.cityType) || undefined,
    districtType: _.toString(mainItem?.districtType) || undefined,
    tenantType: mainItem?.tenantType || enumWarehouseType.self.value,
  };

  const [
    provinceData,
    setProvinceData,
  ] = React.useState<ValueAndLabelData>([]);
  const [
    cityData,
    setCityData,
  ] = React.useState<ValueAndLabelData>([]);
  const [
    districtData,
    setDistrictData,
  ] = React.useState<ValueAndLabelData>([]);

  React.useEffect(() => {
    getAddress(-1, 0)
      .then(setProvinceData);

    if (mainItem?.provinceType) {
      getAddress(mainItem.provinceType, 1)
        .then(setCityData);
    }

    if (mainItem?.cityType) {
      getAddress(mainItem.cityType, 2)
        .then(setDistrictData);
    }
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback({
        ...paramData.params,
        confirmPassword: undefined,
      })
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="主键"
          name="id"
          rules={[{ required: Boolean(mainItem) }]}
          style={{ display: 'none' }}
        >
          <Input
            placeholder="请输入原始密码"
            type="hide"
          />
        </Form.Item>
        <Form.Item
          label="租户类型"
          name="tenantType"
          rules={[{ required: true }]}
        >
          <Radio.Group options={Object.values(enumWarehouseType)}/>
        </Form.Item>
        <Form.Item
          label="仓库名称"
          name="warehouseName"
          rules={[
            {
              required: true,
              message: '请输入仓库名称',
            },
            {
              max: 20,
              message: '仓库名称不超过20个字符',
            },
          ]}
        >
          <Input
            placeholder="请输入仓库名称"
            type="input"
          />
        </Form.Item>
        <Form.Item
          label="手机号"
          name="phone"
          rules={[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern: phoneReg,
              message: '请输入正确手机号(11位)',
            },
          ]}
        >
          <Input
            placeholder="请输入手机号"
            type="input"
          />
        </Form.Item>
        <Form.Item
          label="省"
          name="provinceType"
          rules={[
            {
              required: true,
              message: '请选择省',
            },
          ]}
        >
          <Select
            allowClear={false}
            notFoundContent="没有数据"
            onChange={(value: string) => {
              form.setFields([
                {
                  name: 'cityType',
                  value: undefined,
                },
                {
                  name: 'districtType',
                  value: undefined,
                },
              ]);
              setCityData([]);
              setDistrictData([]);
              getAddress(value, 1)
                .then(setCityData);
            }}
            options={provinceData}
            placeholder="请选择省"
          />
        </Form.Item>
        <Form.Item
          label="市"
          name="cityType"
          rules={[
            {
              required: true,
              message: '请选择市',
            },
          ]}
        >
          <Select
            allowClear={false}
            notFoundContent="没有数据"
            onChange={(value: string) => {
              form.setFields([
                {
                  name: 'districtType',
                  value: undefined,
                },
              ]);
              setDistrictData([]);
              getAddress(value, 2)
                .then(setDistrictData);
            }}
            options={cityData}
            placeholder="请选择市"
          />
        </Form.Item>
        <Form.Item
          label="区"
          name="districtType"
          rules={[
            {
              required: true,
              message: '请选择区',
            },
          ]}
        >
          <Select
            allowClear={false}
            notFoundContent="没有数据"
            options={districtData}
            placeholder="请选择区"
          />
        </Form.Item>
        <Form.Item
          label="详细地址"
          name="address"
          rules={[
            {
              required: true,
              message: '请输入详细地址',
            },
          ]}
        >
          <Input.TextArea
            autoSize={{
              minRows: 2,
              maxRows: 6,
            }}
            maxLength={300}
            placeholder="请输入详细地址"
          />
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[
            {
              required: !mainItem,
              message: '请输入密码',
            },
            {
              pattern: passwordReg,
              message: '密码需包括字母和数字，且长度不小于8位',
            },
          ]}
        >
          <Input
            autoComplete="none"
            placeholder="请输入密码"
            type="password"
          />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirmPassword"
          rules={[
            {
              required: !mainItem,
              message: '请再次输入密码',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('两次输入密码不一致');
                }
              },
            }),
          ]}
        >
          <Input
            autoComplete="none"
            placeholder="请再次输入密码"
            type="password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
