import { message } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { formatNumber, request } from 'egenie-common';
import { action, observable, computed } from 'mobx';
import type { AreaSettingItem, CourierItem } from '../../types';
import type { PreviewModalStore } from '../previewModal/previewModalStore';
import { ENUM_MODULE_TYPE } from '../../../moduleEfficiency/constants';

export class AreaSettingModalStore {
  constructor(private parent: PreviewModalStore) {
  }

  @observable public moduleType: number = null;

  @computed public get isExpressCollection(): boolean {
    return this.moduleType === ENUM_MODULE_TYPE.expressCollection.value;
  }

  private isAdd: boolean = null;

  @observable public areaSettingDetailData: AreaSettingItem[] = [];

  @action public handleItemCountChange = (value: number | string, dataIndex): void => {
    const newValue = formatNumber(value);
    if (this.isExpressCollection) {
      this.areaSettingDetailData[dataIndex].courierInfos = this.areaSettingDetailData[dataIndex].courierInfos.slice(0, newValue);
      while (this.areaSettingDetailData[dataIndex].courierInfos.length < value) {
        this.areaSettingDetailData[dataIndex].courierInfos.push({ courierId: undefined });
      }
    } else {
      this.areaSettingDetailData[dataIndex].deviceInfoList = this.areaSettingDetailData[dataIndex].deviceInfoList.slice(0, newValue);
      while (this.areaSettingDetailData[dataIndex].deviceInfoList.length < value) {
        this.areaSettingDetailData[dataIndex].deviceInfoList.push({ mac: '' });
      }
    }
  };

  public getItemCount = (item: AreaSettingItem): number => {
    return this.isExpressCollection ? item.courierInfos.length : item.deviceInfoList.length;
  };

  @computed public get sumSaturationAging(): number {
    return this.areaSettingDetailData.reduceRight((prev, currentItem) => prev + formatNumber(currentItem.saturationAging) * this.getItemCount(currentItem), 0);
  }

  @computed public get sumSaturationNum(): number {
    return this.areaSettingDetailData.reduceRight((prev, currentItem) => prev + formatNumber(currentItem.saturatedStockNum) * this.getItemCount(currentItem), 0);
  }

  @computed public get sumDeviceTotalLaborCost(): number {
    return this.areaSettingDetailData.reduceRight((prev, currentItem) => prev + formatNumber(currentItem.deviceTotalLaborCost) * this.getItemCount(currentItem), 0);
  }

  @computed public get sumLaborCost(): number {
    return this.areaSettingDetailData.reduceRight((prev, currentItem) => prev + formatNumber(currentItem.laborCost) * this.getItemCount(currentItem), 0);
  }

  @action public getAreaSettingDetail = (): void => {
    this.areaSettingDetailData = [];
    request<BaseData<AreaSettingItem[]>>({
      url: '/api/cloud/wms/rest/cloud/report/module/getAddOrEditModuleConfig',
      method: 'post',
      data: {
        moduleType: this.moduleType,
        tenantId: this.parent.tenantItem.tenantId,
        add: this.isAdd,
      },
    })
      .then((info) => {
        const data = Array.isArray(info.data) ? info.data : [];
        this.areaSettingDetailData = data.map((item) => ({
          ...item,
          deviceInfoList: Array.isArray(item.deviceInfoList) ? item.deviceInfoList : [],
          courierInfos: Array.isArray(item.courierInfos) ? item.courierInfos : [],
        }));
      });
  };

  @observable public visible = false;

  @observable public courierData: ValueAndLabelData = [];

  @action public getCourierData = () => {
    this.courierData = [];
    request<BaseData<CourierItem[]>>({ url: '/api/cloud/wms/rest/cloud/report/module/queryCourierIdAndName' })
      .then((info) => this.courierData = (info.data || []).map((item) => ({
        value: item.id,
        label: item.courierName,
      })));
  };

  @action public handleOpen = (moduleType: number, isAdd: boolean): void => {
    this.moduleType = moduleType;
    this.isAdd = isAdd;
    this.visible = true;
    this.getAreaSettingDetail();
    if (this.isExpressCollection) {
      this.getCourierData();
    }
  };

  @action public handleClose = (): void => {
    this.visible = false;
    this.moduleType = null;
    this.isAdd = null;
    this.areaSettingDetailData = [];
  };

  @observable public isLoading = false;

  @action public handleFinish = (): void => {
    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/cloud/report/module/addOrEditModuleConfig',
      method: 'post',
      data: {
        moduleType: this.moduleType,
        tenantId: this.parent.tenantItem?.tenantId,
        addModulePlan: this.isAdd,
        detailList: this.areaSettingDetailData.map((item) => ({
          wmsModulePlanId: item.wmsModulePlanId,
          deviceInfoList: item.deviceInfoList,
          courierInfos: item.courierInfos,
          number: this.getItemCount(item),
        })),
      },
    })
      .then(() => {
        message.success('操作成功');
        this.handleClose();
        this.parent.getModuleConfigDetail();
      })
      .finally(() => this.isLoading = false);
  };
}
