import { message, Modal } from 'antd';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import type { MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import React from 'react';
import { mainSubStructureModel } from './mainSubStructureModel';
import { PreviewModalStore } from './moduleConfig/previewModal/previewModalStore';
import { normalProgramme } from './normalProgramme';
import type { TenantItem } from './types';
import { UserModal } from './userModal';
import { RefundAddressModal } from './refundAddressModal';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public previewModalStore: PreviewModalStore = new PreviewModalStore();

  public handleDelete = (maiItem: TenantItem) => {
    Modal.confirm({
      content: '确认删除吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/tenant/registry/delete',
          method: 'post',
          data: { id: maiItem.id },
        });
        message.success('删除成功');
        this.mainSubStructureModel.gridModel.resetAll();
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  public handleApprove = (maiItem: TenantItem) => {
    Modal.confirm({
      content: '确认审核吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/tenant/registry/audit',
          method: 'post',
          data: { id: maiItem.id },
        });
        message.success('审核成功');
        this.mainSubStructureModel.gridModel.resetAll();
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  public handleSetMainReturnGoodsWarehouse = (maiItem: TenantItem) => {
    Modal.confirm({
      content: '确认设置退货主仓吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/tenant/registry/set/refund/warehouse',
          method: 'post',
          data: { id: maiItem.id },
        });
        message.success('设置退货主仓成功');
        this.mainSubStructureModel.gridModel.resetAll();
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  public handleAddUser = () => {
    destroyAllModal();
    renderModal(
      <UserModal
        callback={async(data) => {
          await request({
            url: '/api/cloud/wms/rest/tenant/registry/create',
            method: 'post',
            data,
          });
          message.success('创建成功');
          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.gridModel.onQuery();
          destroyAllModal();
        }}
        onCancel={destroyAllModal}
        title="新建云仓租户"
      />
    );
  };

  public handleModifyRefundAddress = (item: TenantItem): void => {
    destroyAllModal();
    renderModal(
      <RefundAddressModal
        callback={async(data) => {
          await request({
            url: '/api/cloud/wms/rest/tenant/registry/set/refund/address',
            method: 'post',
            data: {
              ...data,
              id: item.id,
            },
          });
          message.success('修改成功');
          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.gridModel.onRefresh();
          destroyAllModal();
        }}
        mainItem={item}
        onCancel={destroyAllModal}
      />
    );
  };

  public handleEditUser = (mainItem: TenantItem) => {
    destroyAllModal();
    renderModal(
      <UserModal
        callback={async(data) => {
          await request({
            url: '/api/cloud/wms/rest/tenant/registry/modify',
            method: 'post',
            data,
          });
          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.gridModel.onRefresh();
          destroyAllModal();
        }}
        mainItem={{
          ...mainItem,
          tenantType: mainItem.tenantType ? `${mainItem.tenantType}` : undefined,
        }}
        onCancel={destroyAllModal}
        title="编辑云仓租户"
      />
    );
  };

  public mainSubStructureModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;
}

