import { Card, Layout } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import React from 'react';
import styles from './index.less';
import { PreviewModal } from './moduleConfig/previewModal/previewModal';
import { Store } from './store';

const store = new Store();

export default function() {
  return (
    <>
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <MainSubStructure store={store.mainSubStructureModel}/>
        </Layout.Content>
      </Layout>
      <PreviewModal previewModalStore={store.previewModalStore}/>
    </>
  );
}
